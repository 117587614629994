import React, { useImperativeHandle, useRef, useState } from 'react';
import Stock from '../../model/Stock';
import FormTextItem from '../form/FormTextItem'
import FormSwitchItem from '../form/FormSwitchItem';
import ConfigNotificationSettings from '../form/ConfigNotificationSettings';
import TextInput from '../form/TextInput';
import api, { combinedTicker } from '../../api/api';
import { ChangeTickerRequest } from '../../model/ChangeTickerRequest';
import { getBaseAddress, to } from '../../util/util';
import { history } from '../../index';
import { ArchiveStockRequest } from '../../model/ArchiveStockRequest';
import * as Exchange from '../../model/Exchange'
import useAsyncEffect from 'use-async-effect';
import FormTextArea from '../form/FormTextArea';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../model/IGlobalState';
import * as Roles from '../../model/Roles';
import AlertEx from '../AlertEx';
import IMarketData from '../../model/IMarketData';
import { setStock as setStockAction } from '../../actions/CurrentStock';
import moment from 'moment';
import { IsAdminOrHasRole } from '../../model/Roles';
import { SaveClientStockRequest } from '../../model/SaveClientStockRequest';
import SaveStockResult from '../../model/SaveStockResult';
interface IProps {
    id:string;
    ticker:string;
    exchange:string;
}

export interface IConfigMethods {
    save:()=>Promise<boolean>
}

const Config = React.forwardRef<any, IProps>((props:IProps, ref) => {       

    const user = useSelector((state: IGlobalState) => state.user)
    const currentStock = useSelector((state: IGlobalState) => state.currentStock)
    const [ editingTicker, setEditingTicker ] = useState(false);
    const [ exchanges, setExchanges ] = useState<string[]>([])
    const originalTicker = useRef(props.ticker);
    const baseAddress =  getBaseAddress();
    const dispatch = useDispatch();
    const newStock = { 
        exchange: 'ASX',
        billingDate: new Date().toISOString(),
        announcementStartYear:new Date().getFullYear(),
    } as Stock
    const [ stock, setStock ] = useState(currentStock || newStock)

    
    const isNew = !props.id;    

    useAsyncEffect(async(isMounted)=>{
        if(isNew){
            setExchanges(await api().getExchanges());            
        }else{
            setExchanges( [ props.exchange ]);
        }
    }, [])

    useAsyncEffect(async (isMounted:()=>boolean)=> {
        
        setStock(currentStock || newStock)
      }, [ currentStock ]);

    const onConfigDataChange = (name:string, value:string|boolean) => {        
        const s1 = { ...stock } as any;
        s1[name] = value;                
        setStock(s1);        
    }
    const onChangeCheckbox = (name:string, value:string|boolean) : void => {                
        
        onConfigDataChange(name, value)
    }
    const validateStock = async () : Promise<boolean> =>{
        if(!stock.ticker 
            || stock.ticker.trim().length < 3
            || !stock.ticker.match(/^[a-z0-9]+$/i)
            ){
            await AlertEx('Please define a valid ticker')
            return false;
        }
        return true;
    }

    const getStockFieldsOnly = (state:Stock) : Stock => {
        return {
            id: state.id,
            ticker: state.ticker,
            exchange: state.exchange,
            isin: state.isin,
            companyName: state.companyName,
            pollAsx: state.pollAsx,            
            sendPriceSensitiveAnnouncements: state.sendPriceSensitiveAnnouncements,
            sendNonPriceSensitiveAnnouncements: state.sendNonPriceSensitiveAnnouncements,
            autoSendAnnouncementEmail: state.autoSendAnnouncementEmail,
            autoSendFilter: state.autoSendFilter,
            autoSendNonPriceSensitiveAnnouncements: state.autoSendNonPriceSensitiveAnnouncements,
            announcementStartYear: state.announcementStartYear,
            confirmationEmail: state.confirmationEmail,
            moderatorEmail: state.moderatorEmail,
            contactEmail: state.contactEmail,
            billingDate: state.billingDate || null,
            websiteUrl: state.websiteUrl,
            apiKey: state.apiKey,            
            announcementNotificationEmails: state.announcementNotificationEmails,
            xeroReference: state.xeroReference,
            subscriptionExpired: state.subscriptionExpired,
            companyLogoUrl: state.companyLogoUrl,
            announcementCommentTemplate: state.announcementCommentTemplate,
            disableMarketData: state.disableMarketData,

            currentMarketData: {} as IMarketData,            
            announcements: []            
        }
    }

    useImperativeHandle(ref, ():IConfigMethods => ({

        async save() {
            let result:SaveStockResult|undefined;
            if(isAdmin){
                const validateResult = await validateStock();
                
                if(validateResult){
                    let s1 = getStockFieldsOnly(stock!);                
                    result = await api().saveStock(s1);
                                    
                }
            }else{
                
                const request:SaveClientStockRequest = {
                    ticker: stock.ticker,
                    exchange: stock.exchange,
                    sendPriceSensitiveAnnouncements: stock.sendPriceSensitiveAnnouncements,
                    sendNonPriceSensitiveAnnouncements: stock.sendNonPriceSensitiveAnnouncements,
                    autoSendAnnouncementEmail: stock.autoSendAnnouncementEmail,
                    autoSendFilter: stock.autoSendFilter||'',
                    autoSendNonPriceSensitiveAnnouncements: stock.autoSendNonPriceSensitiveAnnouncements,                    
                    confirmationEmail: stock.confirmationEmail||'',
                    moderatorEmail: stock.moderatorEmail||'',
                    companyLogoUrl: stock.companyLogoUrl||'',
                    announcementCommentTemplate: stock.announcementCommentTemplate||'',
                }
                result = await api().saveClientStock(request);
                return true;
            }
            if(result){
                if(result.success){
                    dispatch(setStockAction(result.stock))
                    return true;
                }else{
                    await AlertEx(result.errorMessage)
                }
            }
            
            
            return false;
                
        }
    
      }))

    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {                
        onConfigDataChange(e.target.name, e.target.value)
    }

    const insertAutoSendFilter = () => {
        onConfigDataChange('autoSendFilter', 'appendix.*(3b|3y)')        
      }


      const getApiKey = async () => {
        if (stock.apiKey) {
            if (!window.confirm('This stock already has an API key\r\n\r\nGenerating a new one will break existing applications that use this key.\r\n\r\nAre you sure?')) {
                return;
            }
        }
        const apiKey = await api().getNewApiKey();
        onConfigDataChange('apiKey', apiKey)
    }

    const onTickerChange = (e:any) => {
        e.target.value = e.target.value.toUpperCase();
        onChange(e)
    }
    const changeTickerClicked = async (e:any) => {
        if(editingTicker){
            
            const newTicker = stock.ticker.trim();
            if(newTicker === originalTicker.current){
                setEditingTicker(false);
                return;
            }
            if(window.confirm(`You are changing ticker from ${originalTicker.current} to ${newTicker}. Are you sure?`)){
                let request:ChangeTickerRequest = { 
                    exchange: stock.exchange,
                    ticker: originalTicker.current,
                    newTicker: stock.ticker,
                }                

                const [err, result] = await to(api().changeTicker(request))                
                if(err || result.errorMessage){
                    alert(err || result.errorMessage)
                    setEditingTicker(false);                                    
                }else{
                    history.push(`/stocks/${combinedTicker(newTicker, stock.exchange)}`);
                    window.location.reload();
                }
            }else{
                onConfigDataChange('ticker', originalTicker.current)
                setEditingTicker(false);
            }
            
            
        }else{
            setEditingTicker(true);
        }
        
    }

    const archiveStockClicked = async (e:any) => {
        if(!window.confirm(`You are archiving ${stock.ticker}:${stock.exchange}.  Are you sure?`)){
            return;
        }
        let request:ArchiveStockRequest = { 
            exchange: stock.exchange,
            ticker: stock.ticker,
        }  
        const [err, result] = await to(api().archiveStock(request))                
        if(err || result.errorMessage){
            alert(err || result.errorMessage)
                                           
        }else{
            
            window.location.reload();
        }
    }
    let exchangeLink = '';
    if(stock != null){
        if(stock.exchange === Exchange.ASX){
            exchangeLink = `https://www2.asx.com.au/markets/company/${stock.ticker}`;
         }else if(stock.exchange === Exchange.LSE){
            exchangeLink = `https://www.londonstockexchange.com/stock/${stock.ticker}/${stock.companyName}/company-page`;
         }else if(stock.exchange === Exchange.FSE){
            exchangeLink = `https://www.boerse-frankfurt.de/equity/${stock.companyName}`;
         }else if(stock.exchange === Exchange.OTC){
            exchangeLink = `https://www.otcmarkets.com/stock/${stock.ticker}`;
         }
    }
     
     

     const isAdmin = user.roles.hasOwnProperty(Roles.Admin)
     
    return <div className="input-group">
               
                {/* <FormTextItem label="Ticker" value={stock.ticker!} onChange={onTickerChange} disabled={!!props.stock.id}/> */}
                <div className="form-item">
                    <div className="config__ticker-container">
                        <div>
                            <div>
                                <label htmlFor="">Ticker</label>
                            </div>
                            <TextInput value={stock.ticker!} name="ticker" onChange={onTickerChange} disabled={!!props.id && !editingTicker} />
                        </div>
                        <div className="config__exchange">
                            <div>
                                <label htmlFor="">Exchange</label>
                            </div>
                            <select 
                            value={stock.exchange!} 
                            name="exchange" 
                            onChange={(e) => { onConfigDataChange(e.target.name, e.target.value) }} 
                            disabled={!isNew} >
                                
                                { exchanges.map((exchange, index) => <option key={index}>{exchange}</option>)}
                                                                
                            </select>
                            
                    {exchangeLink && <a target="_blank"
                        href={exchangeLink}
                        rel="noopener noreferrer"
                        className="dashboard-row__exchange-link"
                    >[View on Exchange]</a>
                    }
                           
                        </div>

                        { (stock.exchange === Exchange.LSE || stock.exchange === Exchange.FSE) && 
                        <div style={{marginLeft: '1rem'}}>
                            <div >
                                <label htmlFor="">ISIN</label>
                            </div>
                            <TextInput value={stock.isin!} name="isin" onChange={(e: any) => { onConfigDataChange(e.target.name, e.target.value) }} />
                        </div>
                        }
                        

                        {!!props.id && isAdmin &&
                        <>
                        <button 
                            className={"config__button " + (editingTicker ? 'config__changeTickerButton__confirming' : '')}
                            onClick={(e)=> { changeTickerClicked(e)}}
                         > { editingTicker ? 'Confirm Change Ticker' : 'Change Ticker'}
                         </button> 

                         <button    
                            className="config__button config__button__archive"                      
                            onClick={(e)=> { archiveStockClicked(e)}}
                         > { stock.archived ? 'Unarchive' : 'Archive'}
                         </button> 
                        </>
                         }
                        
                         
                        
                    </div>                    
                </div>

                <div className="form-item">
                    <div className="spaceBetween">
                        <label htmlFor="">API Key</label>
                        { isAdmin && <button className="button-sm" onClick={getApiKey}>Generate</button> }
                    </div>
                    <TextInput value={stock.apiKey} name='apiKey' onChange={onChange} disabled={!isAdmin} />
                    { isAdmin && stock.apiKey &&
                        <a target="_blank"
                            href={`${baseAddress}/sample.html?apiKey=${stock.apiKey}`}
                            rel="noopener noreferrer"
                            className="dashboard-row__website"
                        >[Test API]</a>
                    }
                    
                </div>

                         { isAdmin && <>
                            <FormTextItem label="Company Name" value={stock.companyName!} onChange={onChange}/>

                            <FormTextItem label="Billing Date" value={ !!stock.billingDate ? moment(stock.billingDate).format('D MMM YYYY'):''} onChange={onChange}/>
                                                    
                            <div className="config__poll-container">
                            <FormSwitchItem label="Poll" name="pollAsx" value={stock.pollAsx} onChange={onChangeCheckbox}/>
                            <FormSwitchItem label="Subscription Expired" name="subscriptionExpired" value={stock.subscriptionExpired} onChange={onChangeCheckbox}/>
                            </div>
                         </>
                         
                         }
                
                

                <ConfigNotificationSettings 
                emailName="sendPriceSensitiveAnnouncements" 
                mailchimpName="autoSendAnnouncementEmail" 
                emailIsChecked={stock.sendPriceSensitiveAnnouncements!} 
                mailchimpIsChecked={stock.autoSendAnnouncementEmail!} 
                onChange={onChangeCheckbox} >
                    Send Price Sensitive
                </ConfigNotificationSettings>

                <ConfigNotificationSettings 
                emailName="sendNonPriceSensitiveAnnouncements" 
                mailchimpName="autoSendNonPriceSensitiveAnnouncements" 
                emailIsChecked={stock.sendNonPriceSensitiveAnnouncements!} 
                mailchimpIsChecked={stock.autoSendNonPriceSensitiveAnnouncements!} 
                onChange={onChangeCheckbox} >
                    Send <em>Non</em> Price Sensitive
                </ConfigNotificationSettings>
                
                
                <div className="form-item">
                    <div>
                    <label htmlFor="">Auto Send Filter</label>
                    <small style={{marginLeft:'10px'}}>Announcements matching this filter will NOT be auto sent <span className="link" onClick={insertAutoSendFilter}>Click here</span> to insert standard filter</small>
                    </div>
                    <TextInput value={stock.autoSendFilter} name='autoSendFilter' onChange={onChange} />
                </div>

                
                
                <FormTextItem label="Confirmation Emails" helperText="Recipients will receive the Mailchimp 'Send Now' email"  name='confirmationEmail' value={stock.confirmationEmail!} onChange={onChange}/>
                
                
                
                
                { isAdmin && <>
                    <FormTextItem label="Poll Start Year" name='announcementStartYear' value={stock.announcementStartYear!} onChange={onChange}/>
                    <FormTextItem label="Website Url" name='websiteUrl' value={stock.websiteUrl!} onChange={onChange}>
                {stock.websiteUrl &&
                        <a target="_blank"
                            href={stock.websiteUrl}
                            rel="noopener noreferrer"
                            className="dashboard-row__website"
                        >[ Open ]</a>
                    }
                </FormTextItem>
               
                
                                
                
                
                <div className="blue-box">
                    <FormTextItem label="Announcement Notification" helperText="Recipients will receive a notification email when an announcement is released."  name='announcementNotificationEmails' value={stock.announcementNotificationEmails!} onChange={onChange}  />
                </div>
                <FormTextItem label="Contact Emails" helperText="The non-billing contact at the Company"  name='contactEmail' value={stock.contactEmail!} onChange={onChange}/>
                <FormSwitchItem label="Disable Market Data" name="disableMarketData" value={stock.disableMarketData} onChange={onChangeCheckbox}/>

                </>
                }
                
                { (isAdmin || IsAdminOrHasRole(user, stock.id, Roles.ReplyToComment)) && <>
                    <FormTextItem label="Moderator Emails" helperText="Recipients will receive comments"  name='moderatorEmail' value={stock.moderatorEmail!} onChange={onChange}/>
                <FormTextItem label="Company Logo Url"   name='companyLogoUrl' value={stock.companyLogoUrl!} onChange={onChange}/>
                <FormTextArea label="Announcement Comment Template" placeholder='Paste template here' rows={10} name="announcementCommentTemplate" value={stock.announcementCommentTemplate||''} onChange={onChange} className='config__announcement-comment-template' />
                </>}
                
                

                
            </div>    
});

export default Config;
